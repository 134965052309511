import React, { useMemo } from 'react';
import { ElementNode } from '@graphcms/rich-text-types';
import { Box, useMediaQuery, useTheme, styled } from '@mui/material';
import { graphql } from 'gatsby';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContentLayout } from '@lib/components/SwContentLayout';
import { SwTableOfContents } from '@lib/components/SwTableOfContents';
import { grey6 } from '@lib/components/bedrock/SwColors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const SidebarContainer = styled(SwFlexBox)`
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: 20px;
    }
`;

const Header = styled(SwContainer)`
    padding-block: ${({ theme }) => theme.spacing(6)};
`;

const GlossaryTemplate = ({ data }) => {
    const isTabletOrLess = useMediaQuery(useTheme().breakpoints.down('md'));

    const tableOfContentsJson = useMemo(
        () =>
            data.page.content.children
                .filter(({ __typename }) => ['GraphCMS_ContentCard', 'GraphCMS_TitleCard'].includes(__typename))
                .flatMap(({ __typename, ...rest }) => {
                    switch (__typename) {
                        case 'GraphCMS_ContentCard':
                            return Array.isArray(rest.content.json) ? rest.content.json : rest.content.json.children;
                        case 'GraphCMS_TitleCard':
                            return { type: 'heading-two', children: [{ text: rest.title }] } as ElementNode;
                    }
                }),
        [data.page.content]
    );

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.resources.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwLayout background={grey6}>
                <Header>
                    <SwTypography component={'h1'} variant={'h3'}>
                        {data.page.title}
                    </SwTypography>
                    <SwLine spacingBottom={0} />
                </Header>

                <Box display={'flex'} flexDirection={'column'}>
                    <SwContainer paddingInline={{ xs: '0px' }}>
                        <SwContentLayout
                            leftSide={
                                isTabletOrLess ? (
                                    <>
                                        <SidebarContainer flexDirection={'column'} gap={16}>
                                            <SwTableOfContents depth={'heading-two'} json={tableOfContentsJson} />
                                        </SidebarContainer>
                                    </>
                                ) : (
                                    <>
                                        <SwTableOfContents depth={'heading-two'} json={tableOfContentsJson} />
                                    </>
                                )
                            }
                        >
                            <SwCmsRenderer content={data.page.content.children} layout={'content'} />
                        </SwContentLayout>
                    </SwContainer>
                </Box>
                <SwWebsiteFooter margin={4} newsletterForm={data.newsletterForm} />
            </SwLayout>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const getGlossaryQuery = graphql`
    query getGlossaryQuery($locale: GraphCMS_Locale, $id: String) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        enPage: graphCmsPage(slug: { eq: "glossary" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(id: { eq: $id }, locale: { eq: $locale }) {
            id
            remoteId
            title
            subtitle
            slug
            seo {
                ...Essentials_GraphCMS_Seo
            }
            content {
                ... on GraphCMS_Stack {
                    children: remoteChildren {
                        ... on GraphCMS_ContentCard {
                            id
                            __typename
                            content {
                                json
                                references {
                                    ...Essentials_GraphCMS_Asset
                                    ...Essentials_GraphCMS_Quote
                                    ...Essentials_GraphCMS_CustomerQuote
                                    ...Essentials_GraphCMS_CallOut
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default GlossaryTemplate;
